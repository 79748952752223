import { detect } from 'detect-browser';

export const getBrowserName = () => detect() || { name: '' };

export const isFirefox = () => getBrowserName().name.toLowerCase().includes('firefox');

export const isTouchScreen = () => {
    let touchEvent = false;
    try {
        document.createEvent('TouchEvent');
        touchEvent = true;
    } catch (e) {
        touchEvent = false;
    }
    const touchPoints =
        typeof window !== 'undefined' &&
        typeof navigator !== 'undefined' &&
        ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    return touchEvent || touchPoints;
};
