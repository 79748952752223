import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './styles.module.scss';
import classnames from 'classnames';

import useMeasure from 'src/shared/hooks/useMeasure';
import MenuItem from 'src/shared/components/MenuItem';
import useHeaderHelpers from 'src/shared/components/Header/hooks/useHeaderHelpers';

type ContentType = {
    showHeader: boolean;
    name: string;
    text: string;
    path?: string;
    subItems: any[];
    gaAction: string;
    hideSideMenu?: boolean;
    onClickSubItem?: () => void;
};

export interface ItemType {
    type?: 'path' | 'button' | 'dropdown' | 'custom';
    content?: ContentType;
    Renderer?: React.FC;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function

const SideMenuCategoryItem = (props: ContentType) => {
    const [isOpen, setIsOpen] = useState(true);
    const { onClickButton } = useHeaderHelpers();
    const [bind, { height: viewHeight }] = useMeasure();
    const { height, opacity } = useSpring({
        from: { height: viewHeight, opacity: 0 },
        to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0 },
    });

    const toggleClass = classnames(styles.toggle, {
        [styles.opened]: isOpen,
    });

    const onClick = (action) => {
        props.onClickSubItem();
        onClickButton(action);
    };

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(false);
        }, 100);
    }, []);

    return (
        <div className={styles.container}>
            <button className={styles.category} onClick={() => setIsOpen(!isOpen)}>
                {props.text}
                <div className={toggleClass}>
                    <i className={`icon-arrow-down ${styles.icon}`} />
                </div>
            </button>
            <animated.div style={{ height, opacity }}>
                <div {...bind} style={{ display: isOpen ? 'block' : 'none' }}>
                    {props.subItems.map((item, idx) => (
                        <MenuItem className={styles.menuItem} type="white" key={`menu-${props.text}-item-${idx}`} href={item.path} onClick={onClick}>
                            {item.text}
                        </MenuItem>
                    ))}
                </div>
            </animated.div>
        </div>
    );
};

SideMenuCategoryItem.defaultProps = {
    path: '',
    hideSideMenu: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClickSubItem: () => {},
};

export default SideMenuCategoryItem;
