import { useTranslation } from 'next-i18next';

import RoutePaths from 'src/config/RoutePaths';

import { FooterMenuCategoryItemType } from '../types';

const useFooterMenuItems: () => FooterMenuCategoryItemType[][] = () => {
    const { t } = useTranslation();

    const catAbout = {
        category: t('about'),
        items: [
            {
                name: t('why_altive_title'),
                path: RoutePaths.WHY_ALTIVE,
                gaCategory: 'marketingQ421',
                gaAction: 'clickWhyAltive',
            },
            {
                name: t('menu_news'),
                path: `${RoutePaths.NEWS}`,
                gaCategory: 'marketingQ421',
                gaAction: 'clickMedia',
            },
            {
                name: t('menu_contact'),
                path: RoutePaths.CONTACT_US,
                gaCategory: 'marketingQ421',
                gaAction: 'clickContactUs',
            },
        ],
    };

    const catHowItWorks = {
        category: t('menu_how_it_works'),
        items: [
            {
                name: t('menu_how_it_works'),
                path: RoutePaths.HOW_IT_WORKS,
                gaCategory: 'marketingQ421',
                gaAction: 'clickHowItWorks',
            },
            {
                name: t('altive_platform_menu_title'),
                path: RoutePaths.ALTIVE_PLATFORM,
                gaCategory: 'marketingQ421',
                gaAction: 'clickFeatures',
            },
            {
                name: t('offerings_menu_title'),
                path: RoutePaths.OFFERINGS,
                gaCategory: 'marketingQ421',
                gaAction: 'clickOfferings',
            },
        ],
    };

    const catPrivateMarket = {
        category: t('article_category_private_market'),
        items: [
            {
                name: t('menu_private_market'),
                path: RoutePaths.PRIVATE_MARKET,
                gaCategory: 'marketingQ421',
                gaAction: 'clickWhyPrivateMarket',
            },
            {
                name: t('article_category_insights'),
                path: RoutePaths.INSIGHTS,
                gaCategory: 'marketingQ421',
                gaAction: 'clickInsights',
            },
            {
                name: t('article_category_events'),
                path: RoutePaths.EVENTS,
                gaCategory: 'marketingQ421',
                gaAction: 'clickEvent',
            },
        ],
    };

    const catLegal = {
        category: t('menu_legal'),
        items: [
            {
                name: t('menu_privacy_policy'),
                path: RoutePaths.PRIVACY_POLICY,
                gaAction: 'clickPrivacyPolicyButton',
            },
            {
                name: t('menu_terms_and_conditions'),
                path: RoutePaths.TERMS_AND_CONDITIONS,
                gaAction: 'clickTermsConditionsButton',
            },
            {
                name: t('professional_investor'),
                path: RoutePaths.PROFESSIONAL_INVESTOR_NOTICE,
                gaAction: 'clickPI',
                gaCategory: 'marketingQ421',
            },
        ],
    };

    return [
        [catAbout, catHowItWorks],
        [catPrivateMarket, catLegal],
    ];
};

export default useFooterMenuItems;
