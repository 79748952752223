import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import useRouter from 'src/shared/hooks/useRouter';

import globalActions from 'src/shared/store/global/actions';
import userActions from 'src/shared/store/user/actions';
import UserStatus from 'src/shared/config/UserStatus';
import RoutePaths from 'src/config/RoutePaths';
import { fireGA } from 'src/utils/gaUtils';

type userMenuItemsType = {
    name: string;
    path?: string;
    showHeader?: boolean;
    onClick: () => void;
}[];

// eslint-disable-next-line no-unused-vars
type funcType = (memberInfo: any) => userMenuItemsType;

const useUserMenuItems: funcType = (memberInfo) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const router = useRouter();

    const onClickLogout = () => {
        fireGA('platform', 'clickLogoutButton', 'header');
        dispatch(
            globalActions.showConfirmDialog({
                text: t('logout_confirmation'),
                onConfirm: () => {
                    dispatch(userActions.logoutUser());
                },
            })
        );
    };

    const goToPage = (path, query = {}) => {
        switch (memberInfo.status) {
            case UserStatus.PENDING:
                router.replacePath(RoutePaths.LOGIN);
                break;
            case UserStatus.APPROVED:
                router.replacePath(path, query);
                break;
            default:
                break;
        }
    };

    return [
        { name: t('mbr_menu_dashboard'), onClick: () => goToPage(RoutePaths.INVESTMENT), showHeader: true },
        { name: t('mbr_menu_portfolio'), onClick: () => goToPage(RoutePaths.PORTFOLIO, { userId: memberInfo.id }), showHeader: true },
        { name: t('mbr_menu_cs'), onClick: () => goToPage(RoutePaths.CONTACT, { userId: memberInfo.id }), showHeader: true },
        { name: t('mbr_menu_logout'), onClick: onClickLogout, showHeader: true },
    ];
};

export default useUserMenuItems;
