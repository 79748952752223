import React from 'react';

import { fireGA } from 'src/utils/gaUtils';

import useCompanyInfo from 'src/hooks/useCompanyInfo';
import styles from './styles.module.scss';
import { useRouter } from 'src/shared/hooks';

type ContactUsDetailProps = {
    gaActionCallFrom: string;
    className?: string;
    contentMargin?: string;
};

const ContactUsDetail = (props: ContactUsDetailProps) => {
    const { gaActionCallFrom, className, contentMargin } = props;
    const router = useRouter();
    const { email, getPhone, getPhoneHref, location, emailHref } = useCompanyInfo();
    const locations = Object.values(location);

    const onContactUsPhoneClick = () => {
        fireGA('marketing', 'clickPhone', gaActionCallFrom);
    };

    const onContactUsEmailClick = () => {
        fireGA('marketing', 'clickEmail', gaActionCallFrom);
    };

    const onContactUsAddressClick = () => {
        fireGA('marketing', 'clickAddress', gaActionCallFrom);
    };

    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.phone}>
                <a href={getPhoneHref(router.locale)} onClick={onContactUsPhoneClick}>
                    {getPhone(router.locale)}
                </a>
            </div>
            <div className={`${styles.email} ${contentMargin}`}>
                <a href={emailHref} target="_blank" rel="noreferrer" onClick={onContactUsEmailClick}>
                    {email}
                </a>
            </div>
            {locations.map((loc, idx) => (
                <div key={`location__${idx}`} className={styles.address}>
                    <a href={loc.addressLink} target="_blank" rel="noreferrer" onClick={onContactUsAddressClick}>
                        {loc.address}
                    </a>
                </div>
            ))}
        </div>
    );
};

ContactUsDetail.defaultProps = {
    className: '',
    contentMargin: '',
};

export default ContactUsDetail;
