import React from 'react';
import GaCategory from 'src/config/GaCategory';
import GaService from 'src/services/GaService';
import useCompanyInfo from 'src/hooks/useCompanyInfo';
import styles from './styles.module.scss';

type ConnectProps = {
    gaActionCallFrom: string;
    className?: string;
};

const Connect = (props: ConnectProps) => {
    const { connects } = useCompanyInfo();
    const { gaActionCallFrom, className } = props;

    const onClickButton = (action) => {
        GaService.callEvent(GaCategory.marketing, action, gaActionCallFrom);
    };

    return (
        <div className={`${styles.connectIcons} ${className}`}>
            {connects.map((connect) => (
                <a
                    onClick={() => onClickButton(connect.gaAction)}
                    key={`connect-${connect.name}`}
                    className={styles.icon}
                    href={connect.href}
                    download={connect.shouldTriggerDownload}
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className={`icon-${connect.icon}`} />
                </a>
            ))}
        </div>
    );
};

Connect.defaultProps = {
    className: '',
};

export default Connect;
