import React from 'react';
import useRouter from 'src/shared/hooks/useRouter';

import styles from './styles.module.scss';

const BackButton = (props) => {
    const router = useRouter();

    const back = () => {
        if (props.link) {
            router.replacePath(props.link);
        } else {
            router.back();
        }
    };

    return (
        <div className={styles.container} onClick={back} role="presentation">
            <i className={`icon-back ${styles.backArrow}`} />
        </div>
    );
};

export default BackButton;
