import React from 'react';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.scss';
import ContactUsDetail from 'src/components/ContactUsDetail';

const ContactUs = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>{t('home_contact_us_title')}</div>
            <ContactUsDetail gaActionCallFrom="footer" />
        </div>
    );
};

export default ContactUs;
