/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

import Link from 'src/shared/components/Link';

class MenuItem extends React.PureComponent {
    render() {
        const { type, className, onClick, href } = this.props;
        /*
         * @TODO update next version after the scroll when shallow fix is released
         * [NextJS issue] The 'scroll' flag is not working now for shallow routing:
         *   https://github.com/vercel/next.js/issues/21606
         */
        if (href) {
            return (
                <div className={`${styles.container} ${styles[type]} ${className}`}>
                    <Link href={href} scroll>
                        <a onClick={onClick}>{this.props.children}</a>
                    </Link>
                </div>
            );
        }

        return (
            <div className={`${styles.container} ${styles[type]} ${className}`}>
                <button type="button" onClick={onClick}>
                    {this.props.children}
                </button>
            </div>
        );
    }
}

MenuItem.defaultProps = {
    type: '',
    className: '',
};

MenuItem.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
};

export default MenuItem;
