import React, { useState } from 'react';

import useMemberInfo from 'src/shared/hooks/useMemberInfo';
import Logo from 'src/shared/components/Logo';
import { fireGA } from 'src/utils/gaUtils';

import HeaderItems from './components/HeaderItems';
import SideMenu from './components/SideMenu';
import BackButton from './components/BackButton';
import styles from './styles.module.scss';
import classNames from 'classnames';
import LanguageSwitcher from '../LanguageSwitcher';

export interface HeaderProps {
    type?: 'white' | 'black' | 'transparent';
    fixed?: boolean;
    isStatementBannerShown: boolean;
    isSticky?: boolean;
    hideMenu?: boolean;
    showMember?: boolean;
    title?: string;
    menuItems?: any[] | null;
    className?: string;
    shouldShowLanguage?: boolean;
    shouldShowShare?: boolean;
    ShareButtons?: (props: any) => JSX.Element;
    shouldShowBack?: boolean;
    backUrl?: string | null;
}

const Header: React.FC<HeaderProps> = ({
    menuItems,
    fixed,
    className,
    isStatementBannerShown,
    isSticky,
    type,
    hideMenu,
    showMember,
    title,
    shouldShowLanguage,
    shouldShowBack,
    backUrl,
    shouldShowShare,
    ShareButtons,
}) => {
    const memberInfo = useMemberInfo();

    const onLogoClick = () => {
        fireGA('marketingQ421', 'clickLogoHome', !memberInfo.isLoggedIn ? 'header' : 'header (logged in)');
    };

    /*
     * As type means text color type,
     * 'black' Header = light background dark text
     * 'transparent' Header = dark background light text
     */
    const stickyBackgroundType = type === 'black' ? 'light' : 'dark';
    const backgroundType = isSticky ? stickyBackgroundType : '';
    const theme = type === 'transparent' ? 'white' : type;

    return (
        <div
            className={classNames(styles.container, styles[backgroundType], {
                [styles.alertShown]: isStatementBannerShown,
                [styles.absolute]: fixed,
                [styles.sticky]: isSticky,
                [className]: className,
            })}
        >
            <div className={styles.content}>
                {shouldShowBack && <BackButton url={backUrl} />}
                {!shouldShowBack && <Logo memberInfo={memberInfo} title={title} theme={theme} onClick={onLogoClick} />}
                <div className={styles.toolContainer}>
                    {menuItems && <HeaderItems isVisible={!hideMenu} memberInfo={memberInfo} theme={theme} items={menuItems} hasLoginControl />}
                    {shouldShowShare && ShareButtons && !hideMenu && <ShareButtons className={styles.shareButtons} />}
                    {menuItems && <SideMenu hideMenu={hideMenu} items={menuItems} showMember={showMember} theme={theme} memberInfo={memberInfo} />}
                </div>
                {shouldShowLanguage && <LanguageSwitcher type="white" />}
            </div>
        </div>
    );
};

Header.defaultProps = {
    type: 'white',
    fixed: false,
    isSticky: false,
    shouldShowLanguage: false,
    shouldShowShare: false,
    hideMenu: false,
    showMember: false,
    title: null,
    menuItems: null,
};

export default Header;
