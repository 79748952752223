import React from 'react';

import styles from './styles.module.scss';

const CopyrightNotice = () => {
    return (
        <div className={styles.container}>
            This Website and any information contained herein has not been reviewed by Hong Kong Securities and Futures Commission or any other
            regulatory authority, and is made available by Altive Limited (hereafter “Altive”) for Professional Investors’ general information only
            and not for any other purpose. For the avoidance of doubt, the term “Professional Investor” refers to and covers Professional Investors as
            defined under the Securities and Futures Ordinance of Hong Kong, Wholesale Clients within the meaning of the Corporations Act 2001 of
            Australia, Professional Investors as defined under Securities and Futures Act of Singapore, or other relevant respective local
            jurisdictions. The Viewer proclaims himself/herself as a Professional Investor by continuing to access the Website. The Viewer agrees that
            this website shall be used solely as reference, or for informational use and not for any other purposes, commercial or otherwise. Altive
            is an asset management company licensed with the Securities and Futures Commission (CE no. BPK587) to carry on business in Type 4
            (advising on securities) and Type 9 (asset management) regulated activities in Hong Kong under the Securities and Futures Ordinance (Cap.
            571). The information contained in this website is not intended and shall not be used or construed as an offer to sell, or a solicitation
            of any offer to buy, securities of any fund or other investment product in any jurisdiction. The information contained herein is qualified
            in its entirety by the terms applicable to the investment funds as set out in its constitutive and offering documents (“Fund Documents”)
            and should be read together with such Fund Documents. Neither Altive nor any of its officers, directors, agents and employees makes any
            warranty, express or implied, of any kind related to the adequacy, accuracy or completeness of any information on this site or the use of
            information on this site. The information in this Website is not intended and should be construed as investment, tax, legal, financial or
            other advice. Altive holds exclusive and rightful ownership of the intellectual and proprietary rights to all opinions, concepts, ideas,
            work products, and the like, related to or as a result of the General Information and contents in this Website.
        </div>
    );
};

export default CopyrightNotice;
