import React from 'react';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.scss';

interface Props {
    onRequestClose: () => void;
}

const STATEMENT = {
    default: {
        title: 'Important Security Alert',
        text:
            'Online scams have become rampant recently. Do not log in to Altive platform or sign any e-documents through links in any suspicious emails, SMS messages, or websites. If you have any doubts, contact Altive to verify the authenticity of the email or SMS.',
    },
    tc: {
        title: '重要網絡保安提示',
        text:
            '近期網上詐騙猖獗，請慎防可疑電郵及短訊。切勿從可疑的電子郵件、SMS短訊或網站登入Altive安投網上平台或簽署任何電子文件。如有懷疑，請聯絡Altive安投以確認電郵或短訊的真偽。',
    },
    sc: {
        title: '重要网络保安提示',
        text:
            '近期网上诈骗猖獗，请慎防可疑电邮及短讯。切勿从可疑的电子邮件、SMS短讯或网站登入Altive安投网上平台或签署任何电子文件。如有怀疑，请联络Altive安投以确认电邮或短讯的真伪。',
    },
};

export default function StatementBanner({ onRequestClose }: Props) {
    const { i18n } = useTranslation();
    const statement = STATEMENT[i18n.language] || STATEMENT.default;

    return (
        <>
            <div className={styles.StatementBanner}>
                <div className={styles.contentWrapper}>
                    <div className={styles.content}>
                        <div className={styles.title}>{statement.title}</div>
                        <div className={styles.statement}>
                            <span>{statement.text}</span>&nbsp;
                            {statement.learnMoreLink && (
                                <a href={statement.learnMoreLink} target="_blank" rel="noreferrer">
                                    {statement.learnMoreButtonText}
                                </a>
                            )}
                        </div>
                    </div>
                    <button className={styles.closeButton} onClick={onRequestClose}>
                        &#10005;
                    </button>
                </div>
            </div>
            <div className={styles.dummy} />
        </>
    );
}
