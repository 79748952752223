import React, { useRef, useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Footer from 'src/components/Footer';
import StatementBanner from 'src/shared/components/StatementBanner';
import Header from 'src/components/Header';
import useRouter from 'src/shared/hooks/useRouter';
import PageMetaHead, { PageMetaHeadProps } from 'src/components/PageMetaHead';
import throttle from 'lodash.throttle';
import { useAtom } from 'jotai';
import { isStatementBannerShownAtom } from 'src/atom/statementBannerAtom';

export interface HeaderPropsType {
    type?: 'white' | 'black' | 'transparent';
    fixed?: boolean;
    hideMenu?: boolean;
    shouldShowBack?: boolean;
    backUrl?: string | null;
    shouldShowShare?: boolean;
    className?: string;
}

// TODO Add local Wrapper for passing local only components
interface Props {
    metaProps?: PageMetaHeadProps;
    headerProps?: HeaderPropsType;
    // There is possibility to add props for footer
    footerProps?: { getAsPath?: (lang: string) => string };
    hideHeader?: boolean;
    hideFooter?: boolean;
    hideMenu?: boolean;
    hideMeta?: boolean;
    className?: string;
    containerClassName?: string;
}

const LayoutWrapper: React.FC<Props> = (props) => {
    const router = useRouter();
    const ref = useRef(null);
    const [isStatementBannerShown, setIsStatementBannerShown] = useAtom(isStatementBannerShownAtom) as any;
    const [isSticky, setSticky] = useState(false);
    const { hideMeta } = props;
    // eslint-disable-next-line prefer-destructuring
    let hideHeader = props.hideHeader;
    // eslint-disable-next-line prefer-destructuring
    let hideFooter = props.hideFooter;

    if (router.query && router.query.cleanView !== undefined) {
        hideHeader = true;
        hideFooter = true;
    }

    useEffect(() => {
        const handleScroll = throttle(() => {
            if (ref.current) {
                setSticky(ref.current.getBoundingClientRect().top < 0);
            }
        }, 200);

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    return (
        <div ref={ref} className={`${styles.rootContainer} ${props.className}`}>
            {!hideMeta && <PageMetaHead {...props.metaProps} />}
            {isStatementBannerShown && <StatementBanner onRequestClose={() => setIsStatementBannerShown(false)} />}
            {!hideHeader && !router.isMobile && (
                <Header isSticky={isSticky} isStatementBannerShown={isStatementBannerShown} hideMenu={props.hideMenu} {...props.headerProps} />
            )}
            <div className={styles.container}>{props.children}</div>
            {!hideFooter && !router.isMobile && <Footer hideMenu={props.hideMenu} {...props.footerProps} />}
        </div>
    );
};

LayoutWrapper.defaultProps = {
    metaProps: {},
    headerProps: {},
    footerProps: {},
    hideHeader: false,
    hideFooter: false,
    hideMenu: false,
    hideMeta: false,
    className: '',
};

export default LayoutWrapper;
