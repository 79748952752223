import React from 'react';
import moment from 'moment';
import { useTranslation } from 'next-i18next';

import styles from './styles.module.scss';

const AllRightReserved = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            @{moment().format('YYYY')} {t('home_all_right_reserved')}
            <div className={styles.version}>
                {t('version {{ version }}', { version: process.env.VERSION })}
            </div>
        </div>
    );
};

export default AllRightReserved;
