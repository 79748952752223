import React, { useEffect, useState } from 'react';
import useRouter from 'src/shared/hooks/useRouter';
import { useTranslation } from 'next-i18next';

import RoutePaths from 'src/config/RoutePaths';
import MenuItem from 'src/shared/components/MenuItem';
import PlainButton from 'src/shared/components/PlainButton';
import Image from 'src/shared/components/Image';
import RoundedButton from 'src/shared/components/RoundedButton';
import Logo from 'src/shared/components/Logo';

import styles from './styles.module.scss';
import useHeaderHelpers from '../../hooks/useHeaderHelpers';
import useUserMenuItems from '../../hooks/useUserMenuItems';
import SideMenuCategoryItem from './components/SideMenuCategoryItem';

const LOGIN_CONTROL_BUTTON_WIDTH = '100%';
const MENU_ICON_WIDTH = '25px';
const HEADER_LOGIN_CONTROL_BUTTON_WIDTH = '110px';

type ContentType = {
    showHeader: boolean;
    showMenu?: boolean;
    name: string;
    text: string;
    path?: string;
    subItems: any[];
    gaAction: string;
    hideSideMenu?: boolean;
};

export interface ItemType {
    type?: 'path' | 'button' | 'dropdown' | 'custom';
    content?: ContentType;
    Renderer?: React.FC;
}

interface Props {
    items: Array<ItemType>;
    hideMenu?: boolean;
    showMember?: boolean;
    theme?: string;
    memberInfo: any;
}

const SideMenu = ({ hideMenu, items, showMember, theme, memberInfo }: Props) => {
    const router = useRouter();
    const { t } = useTranslation();
    const { onClickButton, onClickRoute, onClickLoginSignUpButton } = useHeaderHelpers();
    const [isOpen, setIsOpen] = useState(false);
    const [windowHeight, setWindowHeight] = useState(0);
    const userMenuItem = useUserMenuItems(memberInfo);

    useEffect(() => {
        const updateWindowDimensions = () => setWindowHeight(window.innerHeight);
        window.addEventListener('resize', updateWindowDimensions);
        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    const onClickMenu = () => {
        setIsOpen(!isOpen);
        document.body.classList.toggle('fixed');
    };

    const onCloseMenu = () => {
        setIsOpen(false);
        document.body.classList.remove('fixed');
    };

    const onClickMenuItem = (action) => {
        onCloseMenu();
        onClickButton(action);
    };

    const menuItems = items.map((item) => item.content).filter((item) => !item.hideSideMenu);

    return (
        <div className={`${styles.mobileMenuContainer} ${isOpen && styles.open}`}>
            {(!hideMenu || showMember) && (
                <div className={styles.right}>
                    {!memberInfo.token && (
                        <div className={styles.actions}>
                            <RoundedButton
                                type={theme === 'white' ? 'transparent-on-dark' : 'transparent-on-light'}
                                className={styles.button}
                                width={HEADER_LOGIN_CONTROL_BUTTON_WIDTH}
                                href={RoutePaths.LOGIN}
                                onClick={() => onClickButton('clickLoginButton')}
                            >
                                {t('log_in')}
                            </RoundedButton>
                            <RoundedButton
                                type={theme === 'white' ? 'white' : 'primary-on-light'}
                                // "white"
                                className={styles.button}
                                width={HEADER_LOGIN_CONTROL_BUTTON_WIDTH}
                                href={RoutePaths.REGISTER}
                                onClick={() => onClickButton('clickRegisterButton')}
                            >
                                {t('sign_up')}
                            </RoundedButton>
                        </div>
                    )}
                    <PlainButton className={styles.menuButton} onClick={onClickMenu}>
                        <Image
                            width={MENU_ICON_WIDTH}
                            src={theme === 'white' ? '/static/images/svg/sideMenuIcon.svg' : '/static/images/svg/sideMenuIcon_black.svg'}
                        />
                    </PlainButton>
                </div>
            )}
            <div className={styles.layer} />
            <div className={styles.sidebar} style={{ maxHeight: windowHeight || 'none' }}>
                <div className={styles.content}>
                    <div className={styles.headRow}>
                        <Logo theme="white" onClick={onCloseMenu} />
                        <div className={styles.right}>
                            {memberInfo.token && (
                                <div className={styles.profileImage}>
                                    <img src={memberInfo.profileImageUrl || '/static/images/svg/Profile(New).svg'} />
                                </div>
                            )}
                            <PlainButton className={styles.menuButton} onClick={onCloseMenu}>
                                <Image width={MENU_ICON_WIDTH} src="/static/images/svg/sideMenuCloseIcon.svg" />
                            </PlainButton>
                        </div>
                    </div>
                    {!memberInfo.token && (
                        <div className={styles.actions}>
                            <RoundedButton
                                type="transparent-on-dark"
                                className={styles.button}
                                width={LOGIN_CONTROL_BUTTON_WIDTH}
                                href={RoutePaths.LOGIN}
                                onClick={() => {
                                    onCloseMenu();
                                    onClickLoginSignUpButton('clickLogin');
                                }}
                            >
                                {t('log_in')}
                            </RoundedButton>
                            <RoundedButton
                                type="white"
                                className={styles.button}
                                width={LOGIN_CONTROL_BUTTON_WIDTH}
                                href={RoutePaths.REGISTER}
                                onClick={() => {
                                    onCloseMenu();
                                    onClickLoginSignUpButton('clickSignUp');
                                }}
                            >
                                {t('sign_up')}
                            </RoundedButton>
                        </div>
                    )}
                    {!hideMenu && (
                        <div className={styles.menuItems}>
                            {menuItems
                                .filter((i) => i.showMenu !== false)
                                .map((menu, index) => {
                                    if (menu.subItems) {
                                        return <SideMenuCategoryItem {...menu} onClickSubItem={onCloseMenu} />;
                                    }
                                    return (
                                        <MenuItem
                                            key={index}
                                            className={`${styles.menuItem} ${menu.path === router.pathname ? styles.current : ''}`}
                                            type="white"
                                            href={menu.path}
                                            onClick={() => onClickMenuItem(menu.gaAction)}
                                        >
                                            {t('menu_' + menu.name)}
                                        </MenuItem>
                                    );
                                })}
                        </div>
                    )}
                </div>
                <div className={styles.footer}>
                    <div className={styles.memberMenu}>
                        {memberInfo.token &&
                            userMenuItem
                                .filter((i) => i.showHeader !== false)
                                .map((menu, index) => (
                                    <MenuItem
                                        className={`${styles.menuItem}`}
                                        type="white"
                                        key={index}
                                        onClick={menu.path ? () => onClickRoute(menu.path) : menu.onClick}
                                    >
                                        {menu.name}
                                    </MenuItem>
                                ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

SideMenu.defaultProps = {
    hideMenu: false,
    showMember: false,
    theme: '',
};

export default SideMenu;
