import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';

import RoutePaths from 'src/config/RoutePaths';
import Dropdown from 'src/shared/components/Dropdown';
import RoundedButton from 'src/shared/components/RoundedButton';
import HeaderMenuItem from '../HeaderMenuItem';
import useUserMenuItems from '../../hooks/useUserMenuItems';
import useHeaderHelpers from '../../hooks/useHeaderHelpers';
import styles from './styles.module.scss';
import { isTouchScreen } from 'src/shared/utils/browserUtils';

type ContentType = {
    showHeader: boolean;
    name: string;
    text: string;
    path?: string;
    subItems: any[];
    gaAction: string;
    gaCategory?: string;
};

export interface ItemType {
    type?: 'path' | 'button' | 'dropdown' | 'custom';
    content?: ContentType;
    Renderer?: FC;
}

type HeaderItemsProps = {
    isVisible: boolean;
    hasLoginControl?: boolean;
    theme: 'black' | 'white' | 'transparent';
    items: ItemType[];
    memberInfo: any;
};

const LOGIN_CONTROL_BUTTON_WIDTH = '106px';
const LOGIN_CONTROL_BUTTON_STYLE = { marginLeft: '20px' };

const HeaderItems = ({ isVisible, hasLoginControl, theme, items, memberInfo }: HeaderItemsProps) => {
    const { t } = useTranslation();
    const { onClickButton, onClickRoute, onClickLoginSignUpButton } = useHeaderHelpers();
    const userMenuItems = useUserMenuItems(memberInfo);

    return (
        <div className={styles.menuContainer}>
            {isVisible && (
                <>
                    {items.map((item = {}, idx) => {
                        const key = `menu-item-${idx}`;
                        if (item.type === 'path' && item.content.showHeader) {
                            return (
                                <HeaderMenuItem
                                    key={key}
                                    subItems={item.content.subItems}
                                    type={theme}
                                    path={item.content.path}
                                    text={item.content.text}
                                    gaAction={item.content.gaAction}
                                    gaCategory={item.content.gaCategory}
                                    onClick={onClickButton}
                                />
                            );
                        }
                        if (item.type === 'button') {
                            // @TODO
                        }
                        if (item.type === 'dropdown') {
                            // @TODO
                        }
                        if (item.type === 'custom') {
                            return <item.Renderer key={key} />;
                        }
                        return null;
                    })}
                    {hasLoginControl && !memberInfo.token && (
                        <>
                            <RoundedButton
                                type={theme === 'white' ? 'transparent-on-dark' : 'transparent-on-light'}
                                width={LOGIN_CONTROL_BUTTON_WIDTH}
                                containerStyle={LOGIN_CONTROL_BUTTON_STYLE}
                                href={RoutePaths.LOGIN}
                                onClick={() => onClickLoginSignUpButton('clickLogin')}
                            >
                                {t('log_in')}
                            </RoundedButton>
                            <RoundedButton
                                type={theme === 'white' ? 'white' : 'primary-on-light'}
                                width={LOGIN_CONTROL_BUTTON_WIDTH}
                                containerStyle={LOGIN_CONTROL_BUTTON_STYLE}
                                href={RoutePaths.REGISTER}
                                onClick={() => onClickLoginSignUpButton('clickSignUp')}
                            >
                                {t('sign_up')}
                            </RoundedButton>
                        </>
                    )}
                    {hasLoginControl && memberInfo.token && (
                        <Dropdown
                            type={theme}
                            itemWidth="200px"
                            position={{ y: 20 }}
                            text={`${t('mbr_menu_hello')} ${memberInfo.lastName} ${memberInfo.firstName}`}
                            toggleInHover={!isTouchScreen()}
                            renderButton={() => (
                                <div className={styles.profile}>
                                    <div className={styles.profileImage}>
                                        <img src={memberInfo.profileImageUrl || '/static/images/svg/Profile(New).svg'} />
                                    </div>
                                    <p className={styles.profileText}>{`${memberInfo.lastName} ${memberInfo.firstName}`}</p>
                                </div>
                            )}
                            items={userMenuItems.map((x) => ({ text: x.name, onClick: x.path ? () => onClickRoute(x.path) : x.onClick }))}
                        />
                    )}
                </>
            )}
        </div>
    );
};

HeaderItems.defaultProps = {
    hasLoginControl: false,
};

export default HeaderItems;
