import React from 'react';

import SharedHeader, { HeaderProps } from 'src/shared/components/Header';
import ShareButtons from 'src/components/ShareButtons';

import useMenuItems from './hooks/useMenuItems';

const Header = (props: HeaderProps) => {
    const menuItems = useMenuItems();

    return <SharedHeader {...props} menuItems={menuItems} ShareButtons={ShareButtons} />;
};

Header.defaultProps = {
    shouldShowShare: false,
    shouldShowBack: false,
};

export default Header;
