import GaCategory from 'src/config/GaCategory';
import useRouter from 'src/shared/hooks/useRouter';
import { fireGA } from 'src/utils/gaUtils';

const useHeaderHelpers = () => {
    const router = useRouter();

    const onClickButton = (action, category = '') => {
        if (!action) {
            return;
        }
        fireGA((category || 'marketing') as keyof typeof GaCategory, action, 'header');
    };

    const onClickLoginSignUpButton = (action) => {
        if (!action) {
            return;
        }
        fireGA('marketingQ421', action, 'header');
    };

    const onClickRoute = (path) => {
        router.pushPath(path, null, { shallow: true });
    };

    return {
        onClickButton,
        onClickRoute,
        onClickLoginSignUpButton,
    };
};

export default useHeaderHelpers;
