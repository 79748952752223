import React from 'react';
import { useTranslation } from 'next-i18next';

import Logo from 'src/shared/components/Logo';
import { fireGA } from 'src/utils/gaUtils';

import styles from './styles.module.scss';

const LogoAndDescription = () => {
    const { t } = useTranslation();

    const onLogoClick = () => {
        fireGA('marketingQ421', 'clickLogoHome', 'footer');
    };

    return (
        <div className={styles.container}>
            <Logo onClick={onLogoClick} />
            <div className={styles.description}>{t('footer_description')}</div>
        </div>
    );
};

export default LogoAndDescription;
