import RoutePaths from 'src/config/RoutePaths';
import { useTranslation } from 'next-i18next';

interface MenuItemContent {
    text: string;
    name?: string;
    path?: string;
    gaCategory?: string;
    gaAction?: string;
    showHeader?: boolean;
    hideSideMenu?: boolean;
}

interface MenuItem {
    type: 'path' | 'button' | 'dropdown' | 'custom';
    content: MenuItemContent & {
        subItems?: Array<MenuItemContent>;
    };
}

const useMenuItems = () => {
    const { t } = useTranslation();

    const items: Array<MenuItem> = [
        {
            type: 'path',
            content: {
                text: t('menu_Home'),
                name: 'Home',
                path: RoutePaths.HOME,
                gaAction: 'clickHomeButton',
                gaCategory: 'marketingQ421',
            },
        },
        {
            type: 'path',
            content: {
                text: t('menu_private_market'),
                name: 'WHY PRIVATE MARKET',
                path: RoutePaths.PRIVATE_MARKET,
                showHeader: true,
                gaCategory: 'marketingQ421',
                gaAction: 'clickWhyPrivateMarket',
                subItems: [
                    {
                        text: t('menu_private_market'),
                        name: 'WHY PRIVATE MARKET',
                        path: RoutePaths.PRIVATE_MARKET,
                        showHeader: true,
                        gaCategory: 'marketingQ421',
                        gaAction: 'clickWhyPrivateMarket',
                    },
                    {
                        text: t('menu_insights'),
                        name: 'insights',
                        path: RoutePaths.INSIGHTS,
                        showHeader: true,
                        gaCategory: 'marketingQ421',
                        gaAction: 'clickInsights',
                    },
                ],
            },
        },
        {
            type: 'path',
            content: {
                text: t('menu_How It Works'),
                name: 'How It Works',
                path: RoutePaths.HOW_IT_WORKS,
                showHeader: true,
                subItems: [
                    {
                        text: t('menu_How It Works'),
                        name: 'Altive Platform',
                        path: RoutePaths.HOW_IT_WORKS,
                        showHeader: true,
                        gaCategory: 'marketingQ421',
                        gaAction: 'clickHowItWorks',
                    },
                    {
                        text: t('altive_platform_menu_title'),
                        name: 'Altive Platform',
                        path: RoutePaths.ALTIVE_PLATFORM,
                        showHeader: true,
                        gaCategory: 'marketingQ421',
                        gaAction: 'clickFeatures',
                    },
                    {
                        text: t('offerings_menu_title'),
                        name: 'Offerings',
                        path: RoutePaths.OFFERINGS,
                        showHeader: true,
                        gaCategory: 'marketingQ421',
                        gaAction: 'clickOfferings',
                    },
                ],
                gaCategory: 'marketingQ421',
                gaAction: 'clickHowItWorks',
            },
        },
        {
            type: 'path',
            content: {
                text: t('menu_About'),
                name: 'About',
                path: RoutePaths.WHY_ALTIVE,
                showHeader: true,
                subItems: [
                    {
                        text: t('why_altive_title'),
                        name: 'About',
                        path: RoutePaths.WHY_ALTIVE,
                        showHeader: true,
                        gaCategory: 'marketingQ421',
                        gaAction: 'clickWhyAltive',
                    },
                    {
                        text: t('article_category_media'),
                        name: 'Media',
                        path: RoutePaths.NEWS,
                        showHeader: true,
                        gaCategory: 'marketingQ421',
                        gaAction: 'clickMedia',
                    },
                ],
                gaCategory: 'marketingQ421',
                gaAction: 'clickWhyAltive',
            },
        },
        {
            type: 'path',
            content: {
                text: t('menu_events'),
                name: 'events',
                path: RoutePaths.EVENTS,
                showHeader: true,
                gaCategory: 'marketingQ421',
                gaAction: 'clickEvent',
            },
        },
        {
            type: 'path',
            content: {
                text: t('menu_contact'),
                name: 'contact',
                path: RoutePaths.CONTACT_US,
                showHeader: true,
                gaCategory: 'marketingQ421',
                gaAction: 'clickContactUs',
            },
        },
        {
            type: 'path',
            content: { text: '', name: 'News Detail', path: RoutePaths.NEWS_DETAIL, showHeader: false, hideSideMenu: true },
        },
    ];

    return items;
};

export default useMenuItems;
