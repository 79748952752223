import React from 'react';
import { useTranslation } from 'next-i18next';

import LanguageSwitcher from 'src/shared/components/LanguageSwitcher';

import styles from './styles.module.scss';

interface Props {
    getAsPath?: (lang: string) => string;
}

const Language = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>{t('footer_language_title')}</div>
            <LanguageSwitcher isInline className={styles.languageSwitch} getAsPath={props.getAsPath} />
        </div>
    );
};

Language.defaultProps = {
    getAsPath: null,
};

export default Language;
