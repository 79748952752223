import React from 'react';
import { useTranslation } from 'next-i18next';
import SocialConnect from 'src/components/SocialConnect';
import styles from './styles.module.scss';

const Connect = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitle}>{t('footer_connect_title')}</div>
            <SocialConnect gaActionCallFrom="footer" />
        </div>
    );
};

export default Connect;
