import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import classnames from 'classnames';

import useMeasure from 'src/shared/hooks/useMeasure';
import useResize from 'src/shared/hooks/useResize';
import MenuItem from 'src/shared/components/MenuItem';
import { fireGA } from 'src/utils/gaUtils';

import useFooterMenuItems from './hooks/useFooterMenuItems';
import { FooterMenuCategoryItemType } from './types';
import styles from './styles.module.scss';

interface FooterMenuProps {
    className?: string;
}

interface FooterMenuCategoryProps {
    item: FooterMenuCategoryItemType;
    isMobile: boolean;
}

interface FooterMenuCategoryColumnProps {
    categories: FooterMenuCategoryItemType[];
    isMobile: boolean;
}

const onClickButton = (action, category) => {
    fireGA(category || 'marketing', action, 'footer');
};

const FooterMenuCategory = ({ item: { category, items }, isMobile }: FooterMenuCategoryProps) => {
    const [isOpen, setIsOpen] = useState(true);

    const [bind, { height: viewHeight }] = useMeasure();
    const { height, opacity } = useSpring({
        from: { height: viewHeight, opacity: 0 },
        to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0 },
    });

    useEffect(() => {
        setIsOpen(!isMobile);
    }, [isMobile]);

    const toggleClass = classnames(styles.toggle, {
        [styles.opened]: isOpen,
    });

    return (
        <div className={styles.categoryContainer}>
            <button className={styles.category} disabled={!isMobile} onClick={() => setIsOpen(!isOpen)}>
                {category}
                {isMobile && (
                    <div className={toggleClass}>
                        <i className={`icon-arrow-down ${styles.icon}`} />
                    </div>
                )}
            </button>
            <animated.div style={{ height, opacity }}>
                <div {...bind} style={{ display: isOpen ? 'block' : 'none' }}>
                    {items.map((item, idx) => (
                        <MenuItem
                            className={styles.menuItem}
                            type="white"
                            key={`menu-${category}-item-${idx}`}
                            href={item.path}
                            onClick={() => onClickButton(item.gaAction, item.gaCategory)}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </div>
            </animated.div>
        </div>
    );
};

const FooterMenuCategoryColumn = ({ categories, isMobile }: FooterMenuCategoryColumnProps) => {
    return (
        <div className={styles.categoryColumn}>
            {categories.map((category, idx) => (
                <FooterMenuCategory key={`menu-category-col-${idx}`} isMobile={isMobile} item={category} />
            ))}
        </div>
    );
};

const FooterMenu = ({ className }: FooterMenuProps) => {
    const menuItems = useFooterMenuItems();

    const { isMobile } = useResize();

    return (
        <div className={`${styles.container} ${className}`}>
            {menuItems.map((categories, idx) => (
                <FooterMenuCategoryColumn key={`menu-category-${idx}`} isMobile={isMobile} categories={categories} />
            ))}
        </div>
    );
};

FooterMenu.defaultProps = {
    className: '',
};

export default FooterMenu;
