/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import styles from './styles.module.scss';

declare global {
    interface Window {
        // eslint-disable-next-line camelcase
        addthis_reload: () => void;
        // eslint-disable-next-line camelcase
        addthis_share: any;
        // eslint-disable-next-line camelcase
        addthis_config: any;
        addthis: any;
    }
}

interface MinimalShareButtonProps {}

interface Props {
    className?: string;
    isMinimal?: boolean;
    isLabelShown?: boolean;
    shareTitle: string;
    url: string;
}

// TODO confirm with designer the behaviour of minimal button on web
// TODO navigator.share() for mobile
const MinimalShareButton = (props: MinimalShareButtonProps) => {
    return <div className={styles.minimalContainer}></div>;
};

const ShareButtons = (props: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (window.addthis && window.addthis.layers && window.addthis.layers.refresh) {
            window.addthis.layers.refresh();
        }
    }, []);

    return (
        <div className={`${styles.container} ${props.className}`}>
            {props.isLabelShown && <span>{t('share_button_label')}&nbsp;</span>}
            {props.isMinimal && <MinimalShareButton />}
            {!props.isMinimal && <div className="addthis_inline_share_toolbox" data-url={props.url} data-title={props.shareTitle}></div>}
        </div>
    );
};

ShareButtons.defaultProps = {
    className: '',
    isMinimal: false,
    isLabelShown: false,
};

export default ShareButtons;
