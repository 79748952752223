import React from 'react';

import LogoAndDescription from './components/LogoAndDescription';
import FooterMenu from './components/FooterMenu';
import ContactUs from './components/ContactUs';
import Language from './components/Language';
import Connect from './components/Connect';
import AllRightReserved from './components/AllRightReserved';
import CopyrightNotice from './components/CopyrightNotice';
import styles from './styles.module.scss';

type FooterProps = {
    hideMenu?: boolean;
    getAsPath?: (lang: string) => string;
};

// @TODO move member portal only logic to member portal component
const Footer = (props: FooterProps) => {
    const { hideMenu } = props;

    return (
        <div className={`${styles.container}`}>
            <div className={styles.content}>
                <div className={styles.upperContainer}>
                    <div className={styles.logoDescSubSection}>
                        <LogoAndDescription />
                        <div className={styles.infoContainer}>
                            <ContactUs />
                            <Language getAsPath={props.getAsPath} />
                            <Connect />
                        </div>
                    </div>
                    <div className={styles.menuAndContactUs}>
                        {!hideMenu && <FooterMenu />}
                        <div className={styles.contactUs}>
                            <ContactUs />
                        </div>
                    </div>
                </div>
                <div className={styles.bottomContainer}>
                    <div className={styles.langAndSocialMediaContainer}>
                        <Language getAsPath={props.getAsPath} />
                        <Connect />
                    </div>
                    <div className={styles.copyrightContainer}>
                        <AllRightReserved />
                        <CopyrightNotice />
                    </div>
                </div>
            </div>
        </div>
    );
};

Footer.defaultProps = {
    hideMenu: false,
    getAsPath: null,
};

export default Footer;
