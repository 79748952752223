/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import MenuItem from 'src/shared/components/MenuItem';
import Dropdown from 'src/shared/components/Dropdown';
import styles from './styles.module.scss';
import { isTouchScreen } from 'src/shared/utils/browserUtils';

type HeaderMenuItemProps = {
    type?: 'white' | 'black' | 'transparent';
    text: string;
    path?: string;
    gaCategory?: string;
    gaAction?: string;
    subItems?: any[];
    onClick?: (gaAction: string, gaCategory?: string) => void;
};

const HeaderMenuItem = ({ text, type, path, subItems, gaAction, gaCategory, onClick }: HeaderMenuItemProps) => {
    if (subItems) {
        return (
            <Dropdown
                className={styles.dropdown}
                text={text}
                itemWidth="200px"
                type={type}
                toggleInHover={!isTouchScreen()}
                items={subItems
                    .filter((i) => i.showHeader !== false)
                    .map((item) => ({
                        text: item.text,
                        path: item.path,
                        isShallow: true,
                        onClick: () => onClick(item.gaAction, item.gaCategory),
                    }))}
                renderButton={() => (
                    <MenuItem className={styles.item} type={type} href={isTouchScreen() ? '' : path} onClick={() => onClick(gaAction, gaCategory)}>
                        {text}
                    </MenuItem>
                )}
            />
        );
    }

    return (
        <MenuItem className={styles.item} type={type} href={path} onClick={() => onClick(gaAction, gaCategory)}>
            {text}
        </MenuItem>
    );
};

HeaderMenuItem.defaultProps = {
    type: 'white',
    path: '',
    gaCategory: '',
    gaAction: '',
    onClick: () => {
        // do nothing
    },
    subItems: null,
};

export default HeaderMenuItem;
